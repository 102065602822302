import { type UseAccountProps, useAccount } from '../../entities-v2';

export const useVexMyAccount = (
  props: Omit<UseAccountProps, 'id'> = {}
): ReturnType<typeof useAccount> & { isGuest: boolean } => {
  const { data, error, ...rest } = useAccount({ ...props, id: 'me' });

  const isGuest = !data?.user || (!!error && error.code === '401');

  return {
    data,
    error,
    isGuest,
    ...rest,
  };
};
