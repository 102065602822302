import { Button, Container, Divider, Form, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { FormWidgetProps, W9FileFormModel } from '../../types';
import { useUploadW9FileSchema } from './useCollectW9Schema';
import { ZenworkIcon } from './zenwork.icon';

export type CollectW9FileWidgetProps = {
  onTaxPayerButtonClicked: () => void;
  isLoadingZenworkUrl: boolean;
  showZenworkW9Form: boolean;
  onValidationError?: (isRequiredError: boolean) => void;
  onReplaceFile?: () => void;
} & FormWidgetProps<W9FileFormModel>;

export const CollectW9FileWidget = forwardRef<CollectW9FileWidgetProps, 'form'>(
  (
    {
      isSaving,
      onSubmit,
      onTaxPayerButtonClicked,
      showZenworkW9Form,
      isLoadingZenworkUrl,
      onSubmissionStateChange,
      onValidationError,
      onReplaceFile,
      ...props
    },
    ref
  ) => {
    const schema = useUploadW9FileSchema();
    const { formatMessage } = useMelioIntl();
    const {
      registerField,
      formProps,
      submitButtonProps,
      formState: { errors },
    } = useMelioForm<W9FileFormModel>({
      onSubmit,
      isSaving,
      schema,
      onSubmissionStateChange,
      defaultValues: {
        w9file: null,
      },
    });

    useEffect(() => {
      if (onValidationError && !isEmpty(errors)) {
        const isRequiredError = !!Object.values(errors).some((error) => error?.type === 'required');
        onValidationError(isRequiredError);
      }
    }, [errors, onValidationError]);

    return (
      <Form data-component="CollectW9FileWidget" {...props} {...formProps} ref={ref}>
        {showZenworkW9Form && (
          <>
            <Container>
              <Button
                size="large"
                data-testid="tax-payer-button"
                onClick={onTaxPayerButtonClicked}
                isFullWidth
                isLoading={isLoadingZenworkUrl}
                label={formatMessage('vex.widgets.collectW9File.button.title')}
              />
              <Container paddingTop="s" alignItems="center" justifyContent="center">
                <Text textStyle="caption1">{formatMessage('vex.widgets.collectW9File.poweredBy')}</Text>
                <ZenworkIcon data-testid="zenwork-icon" paddingX={2} />
              </Container>
            </Container>
            <Divider label={formatMessage('vex.widgets.collectW9File.divider.text')} />
          </>
        )}
        <Text>{formatMessage('vex.widgets.collectW9File.uploadFile.title')}</Text>
        <Form.FileUpload
          placeholder={formatMessage('vex.widgets.collectW9File.uploadFile.placeholder')}
          data-testid="upload-w9file-input"
          {...registerField('w9file')}
          acceptTypes={['pdf', 'png', 'jpg']}
          onReplace={() => onReplaceFile?.()}
        />
        <Button
          {...submitButtonProps}
          variant="secondary"
          size="large"
          data-testid="upload-w9file-submit-button"
          label={formatMessage('vex.widgets.collectW9File.uploadFile.button')}
        />
      </Form>
    );
  }
);

CollectW9FileWidget.displayName = 'CollectW9FileWidget';
