import { merge } from 'lodash';

import { fiservConfigBase } from '@/configByPartner/fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from '@/configByPartner/partnerConfig.utils';

const fiservUsBankConfigBase: PartnerConfigByEnv = {
  default: {},
  dev: {},
  prod: {},
};

export const fiservUsBankConfig = extendDefaultConfig(
  merge<PartnerConfigByEnv>({}, fiservConfigBase, fiservUsBankConfigBase),
);
