import { merge } from 'lodash';

import { fiservConfigBase } from '@/configByPartner/fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from '@/configByPartner/partnerConfig.utils';

const fiservXDDemoConfigBase: PartnerConfigByEnv = {
  default: {},
  dev: {},
  prod: {},
};

export const fiservXDDemoConfig = extendDefaultConfig(
  merge<PartnerConfigByEnv>({}, fiservConfigBase, fiservXDDemoConfigBase),
);
