import { LocalStorageKeys } from '@melio/local-storage';
import { PartnerName } from '@melio/platform-api';

import { partnerHostToPartnerName, tenantToPartnerConfiguration } from '@/partnersConfig.types';

export const getPartnerNameFromPath = () => {
  const Partners = Object.values(PartnerName)
    // off-the-shelf partners that we'll eventually move to store the config in db
    .concat(['gusto', 'optum-bank-demo', 'paypal', 'fiserv_wafed'] as unknown as string[]);
  const basePath = getBasePath();
  return Partners.find((partner) => partner === (basePath as unknown));
};

export const getBasePath = () => {
  return location.pathname.split('/')[1];
};

export const getPartnerNameFromHost = () =>
  Object.keys(partnerHostToPartnerName).includes(location.hostname)
    ? partnerHostToPartnerName[location.hostname]
    : null;

export const getPartnerNameFromUrlParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const tenant = queryParams.get('tenant');
  if (!tenant) {
    return null;
  }

  return tenantToPartnerConfiguration[tenant];
};

export const getPartnerNameFromLocalStorage = () => {
  return window.sessionStorage.getItem(LocalStorageKeys.partnerName) as string;
};

export const getPartnerNameFromUrl = () => {
  return (getPartnerNameFromPath() || getPartnerNameFromHost()) as string;
};

export const getPartnerName = () => {
  return getPartnerNameFromUrl() || getPartnerNameFromLocalStorage();
};

export const getPartnerUrlPrefixFromUrl = () => {
  const basePath = window.sessionStorage.getItem(LocalStorageKeys.basePath);
  return basePath ? `/${basePath}` : '';
};
