import { useParams } from 'react-router-dom';
import { PennyProvider } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { VendorOnboardingActivity } from '@melio/vex-activities';

import { ftiTheme } from '@/cl/theme/partners';

export const VendorOnboardingRoute = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { onboardingSessionUuid } = useParams<{ onboardingSessionUuid: string }>();

  if (!onboardingSessionUuid) {
    return null;
  }
  setAnalyticsProperties({ onboardingSessionUuid, EntryPoint: 'partner-vendor-onboarding', Flow: 'vendor-onboarding' });

  return (
    <PennyProvider theme={ftiTheme}>
      <VendorOnboardingActivity onboardingSessionUuid={onboardingSessionUuid} />
    </PennyProvider>
  );
});

VendorOnboardingRoute.displayName = 'VendorOnboardingRoute';
