import { useUserPreference } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

// For our purposes, users will be considered onboarded when the `payDashboardTourActive` flag in preferences
// is both present & set to false. This is the closest we can get to identifying that a user has completed onboarding
export const useIsDashboardTourComplete = () => {
  const [isPayDashboardTourEnabled] = useDevFeature<boolean>(FeatureFlags.IsPayDashboardTourEnabled, false);

  const { data: payDashboardTour, isLoading: isTourCompleteLoading } = useUserPreference({
    id: 'payDashboardTourActive',
    enabled: isPayDashboardTourEnabled,
  });

  return {
    isTourCompleteLoading,
    isTourComplete: payDashboardTour ? payDashboardTour?.value === 'false' : undefined,
  };
};
