import React from 'react';
import { Box } from '@chakra-ui/react';
import { CSSObject } from '@emotion/styled';
import { isEmpty } from 'lodash';

export const CDN_BRANCH_COOKIE_NAME = 'orig_cdn_branch_override';

const getCookieValueByName = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const EnvironmentRibbon = () => {
  const envName = getCookieValueByName(CDN_BRANCH_COOKIE_NAME);

  if (isEmpty(envName)) {
    return null;
  }
  return (
    <Box data-testid="environment-ribbon" sx={RibbonWrapperStyle}>
      <Box sx={RibbonStyle}>
        <Box sx={EnvNameStyle}>{envName}</Box>
      </Box>
    </Box>
  );
};

const RibbonWrapperStyle: CSSObject = {
  w: '150px',
  h: '150px',
  pos: 'absolute',
  overflow: 'hidden',
  top: 0,
  zIndex: 9999,
  right: 0,
  pointerEvents: 'none',
};

const RibbonStyle = {
  pos: 'absolute',
  zIndex: 9999,
  transform: 'rotate(45deg)',
  top: '32px',
  right: '-56px',
  backgroundColor: '#86efac',
  display: 'flex',
};

const EnvNameStyle = {
  color: 'white',
  w: '200px',
  display: 'inline-block',
  py: '5px',
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 700,
};
