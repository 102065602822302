import { FC, useCallback, useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { ExternalLayout, Link } from '@melio/penny';
import { ApiError, useExchangeVexActionToken } from '@melio/platform-api';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig } from '@melio/platform-provider';
import { useSessionConfig } from '@melio/vex-activities';
import { BaseErrorScreen } from '@melio/vex-activities/src/components/base-screens';
import { VexMonitoredAction } from '@melio/vex-domain';

import { useRouter } from '@/hooks/router.hooks';
import {
  useVendorActionAccountEmail,
  useVendorActionAccountName,
  useVendorActionPaymentId,
  useVendorActionUnilateralRequestId,
  useVendorActionVendorId,
  useVendorActionVirtualCardId,
} from '@/store/VendorActions/VendorAction.model';
import { usePlatformIntl } from '@/translations/Intl';
import { ActionData, TokenPayload, VendorAction } from './types';

const getUserPayloadFromToken = (token: string): TokenPayload => jwtDecode<TokenPayload>(token);

const isInvalidOrExpiredToken = (tokenError: ApiError | null): boolean => tokenError?.code === '400';

const AuthActionRouteComponent: FC<{ token: string }> = ({ token }) => {
  const {
    settings: {
      vex: {
        zdSupportFormUrl,
        unilateral: { isNewUnilateral },
      },
    },
  } = useConfig();
  const navigate = useNavigate();

  const {
    goToNewUnilateral,
    goToSuvcAcceptance,
    goToShiftVirtualCardToACH,
    goToVexPaymentUpgrade,
    goToVendorOnboarding,
    goToVexVendorPaymentTracking,
    goToCollectW9,
  } = useRouter();
  const { formatMessage } = usePlatformIntl();
  const { setAccessTokens } = useSessionConfig();
  const { startAction, endAction, routeReady } = useMonitoring<VexMonitoredAction>();

  const {
    isLoading,
    data: exchangeTokenData,
    error: exchangeTokenError,
    mutateAsync: exchangeVexActionToken,
  } = useExchangeVexActionToken();

  useEffect(() => {
    startAction(VexMonitoredAction.AuthExchange);
    return () => {
      endAction(VexMonitoredAction.AuthExchange);
    };
  }, [startAction, endAction]);

  useEffect(() => {
    void exchangeVexActionToken(token);
  }, [exchangeVexActionToken, token]);

  const setPaymentId = useVendorActionPaymentId();
  const setVendorId = useVendorActionVendorId();
  const setAccountName = useVendorActionAccountName();
  const setAccountEmail = useVendorActionAccountEmail();
  const setUnilateralRequestId = useVendorActionUnilateralRequestId();
  const setVirtualCardId = useVendorActionVirtualCardId();
  const setVendorDataAndRoute = useCallback(
    ({
      token,
      vendorId,
      paymentId,
      unilateralRequestId,
      virtualCardId,
      route,
    }: {
      token: string;
      paymentId?: string | undefined;
      vendorId?: string | undefined;
      unilateralRequestId?: string | undefined;
      virtualCardId?: string;
      route: () => void;
    }) => {
      const { account, user } = getUserPayloadFromToken(token);

      if (vendorId) {
        setVendorId(vendorId);
      }

      if (account?.accountName) {
        setAccountName(account.accountName);
      }
      if (user?.email) {
        setAccountEmail(user.email);
      }
      if (paymentId) {
        setPaymentId(paymentId);
      }
      if (unilateralRequestId) {
        setUnilateralRequestId(unilateralRequestId);
      }
      if (virtualCardId) {
        setVirtualCardId(virtualCardId);
      }
      route();
    },
    [setAccountEmail, setAccountName, setPaymentId, setUnilateralRequestId, setVendorId, setVirtualCardId],
  );

  /** @description Invoked when data converted from action token is changes */
  useEffect(() => {
    if (exchangeTokenData) {
      const { accessToken, refreshToken } = exchangeTokenData;
      setAccessTokens(accessToken, refreshToken);
      const { actionData } = jwtDecode<{ actionData: ActionData; accountId: string }>(token);

      switch (actionData.action) {
        case VendorAction.AcceptPaymentWithMsnEnrollment: {
          if (actionData.paymentId) {
            setVendorDataAndRoute({
              token: accessToken,
              paymentId: actionData.paymentId,
              vendorId: actionData.vendorId,
              unilateralRequestId: actionData.unilateralRequestId,
              route: () => goToNewUnilateral(actionData.paymentId as string),
            });
          }
          break;
        }
        case VendorAction.ReadVirtualCardDetails: {
          setVendorDataAndRoute({
            token: accessToken,
            paymentId: actionData.paymentId,
            virtualCardId: actionData.virtualCardId,
            route: () => goToSuvcAcceptance(actionData.paymentId),
          });
          break;
        }
        case VendorAction.AcceptPayment: {
          if (isNewUnilateral) {
            if (actionData.paymentId) {
              setVendorDataAndRoute({
                token: accessToken,
                paymentId: actionData.paymentId,
                vendorId: actionData.vendorId,
                unilateralRequestId: actionData.unilateralRequestId,
                route: () => goToNewUnilateral(actionData.paymentId as string),
              });
            }
          } else {
            navigate(`../../../auth/action?token=${token}`);
          }
          break;
        }
        case VendorAction.ShiftVirtualCardToACH: {
          setVendorDataAndRoute({
            token: accessToken,
            paymentId: actionData.paymentId,
            virtualCardId: actionData.virtualCardId,
            route: () => goToShiftVirtualCardToACH(actionData.paymentId),
          });
          break;
        }
        case VendorAction.UpsellPayment: {
          setVendorDataAndRoute({
            token: accessToken,
            paymentId: actionData.paymentId,
            route: () => goToVexPaymentUpgrade(actionData.paymentId),
          });
          break;
        }
        case VendorAction.RegisterPartnerVendor: {
          setVendorDataAndRoute({
            token: accessToken,
            route: () => goToVendorOnboarding(actionData.onboardingSessionUuid),
          });
          break;
        }
        case VendorAction.CollectW9: {
          setVendorDataAndRoute({
            token: accessToken,
            vendorId: actionData.vendorId,
            route: () => goToCollectW9(actionData.vendorId),
          });
          break;
        }
        case VendorAction.VendorPaymentTracking: {
          setVendorDataAndRoute({
            token: accessToken,
            paymentId: actionData.paymentId,
            vendorId: actionData.vendorId,
            route: () => goToVexVendorPaymentTracking(actionData.paymentId),
          });
          break;
        }
      }
    }
  }, [
    exchangeTokenData,
    goToNewUnilateral,
    setAccessTokens,
    setVendorDataAndRoute,
    goToSuvcAcceptance,
    goToShiftVirtualCardToACH,
    goToVexPaymentUpgrade,
    goToVendorOnboarding,
    isNewUnilateral,
    navigate,
    goToVexVendorPaymentTracking,
    goToCollectW9,
    token,
  ]);

  if (isInvalidOrExpiredToken(exchangeTokenError)) {
    return (
      <BaseErrorScreen
        description={formatMessage('vex.auth.offerExpired.text', {
          link: <Link newTab href={zdSupportFormUrl} label={formatMessage('vex.auth.offerExpired.link')} />,
        })}
      />
    );
  }

  return <ExternalLayout ref={routeReady} isLoading={isLoading} />;
};

export const AuthActionRoute: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    return <Navigate replace to="../404" relative="path" />;
  }

  return <AuthActionRouteComponent token={token} />;
};
