import { RoleUniqueNames } from '@melio/platform-api';
import { PaymentSchedulingPreference } from '@melio/platform-provider';

import { extendDefaultConfig } from '@/configByPartner/partnerConfig.utils';
import capOneLogo from '@/images/capone/capOneAvatarLogo.svg';
import { sbbLinks } from '@/links';

const SBB_TUTORIAL_VIDEO = 'https://player.vimeo.com/video/892347171';

export const sbbConfig = extendDefaultConfig({
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/sbb/messages.json',
    tabTitle: 'Pay Business Bills',
    settings: {
      settings: {
        options: [
          {
            type: 'company',
            items: [
              'profile-settings',
              'company-settings',
              'collaborators-setting',
              'notification-preferences',
              'workflows-setting',
              'accounting-software-sync',
            ],
          },
          {
            type: 'payments',
            items: [
              'payment-methods-setting',
              'subscription-plans-setting',
              'billing-setting',
              'receiving-methods-settings',
            ],
          },
          {
            type: 'ar',
            items: ['receiving-methods-settings', 'invoice-items', 'invoice-settings', 'invoice-email-notifications'],
          },
          {
            type: 'support',
            items: ['support-setting'],
          },
        ],
      },
      collaborator: {
        rolesOrderMapping: {
          [RoleUniqueNames.CapitalOnePrimary]: 1,
          [RoleUniqueNames.CapitalOneAm]: 2,
        },
      },
      partnerProductName: 'Pay Bills',
      assets: {},
      fundingSourcePolicy: {
        'bank-account': {
          fundingSourceGroup: 'bank-account',
          read: true,
          order: 0,
        },
        credit: {
          read: true,
          write: true,
          fundingSourceGroup: 'credit',
          order: 1,
        },
        debit: {
          read: true,
          write: false,
          fundingSourceGroup: 'debit',
          order: 2,
        },
      },
      icons: {
        fundingSource: {
          bank: 'capital-one',
        },
      },
      payment: {
        scheduling: {
          scheduleBy: PaymentSchedulingPreference.ByDeliveryDate,
          maxDeliveryDateInDays: 364,
        },
      },
      trustedPartners: ['Mastercard', 'Quickbooks', 'Visa'],
      supportEmail: 'support@capitalonebusinessbills.melio.com',
      eoyCheck: {
        enabled: true,
        promoteFastCheck: true,
        link: 'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702497316508-When-will-my-payment-arrive',
      },
      helpCenterUrl: 'https://capitalonebusinessbills.zendesk.com/hc/en-us',
      newPayExperience: {
        firstTimeExperience: {
          assetType: 'video',
          videoLink: SBB_TUTORIAL_VIDEO,
        },
      },
      syncProgressIndicationEnabled: true,
      supportPhoneNumber: '',
      unmaskVendorAccountNumber: true,
      vendor: {
        createVendor: {
          shouldCollectAccountNumberForUnmanagedVendor: true,
        },
      },
      promoteAchDeliveryMethod: true,
      MtlKycUpliftCompanyDetails: {
        enabled: true,
        flagName: 'platform-mtl-kyc-uplift-company-details',
      },
      PromoteAccountingSoftwareSync: {
        enabled: true,
        flagName: 'platform-promote-accounting-software-sync',
      },
      PromoteCardFundingSource: {
        enabled: true,
        flagName: 'platform-promote-card-funding-source',
      },
    },
    services: {
      forethoughtApiKey: '',
      zendeskKey: 'f9a6b194-bfd4-4aa3-acfc-058744bc860f',
      zendeskWorkflow: '',
    },
    tutorialVideo: SBB_TUTORIAL_VIDEO,
    zendesk: {
      useNewWidgetApi: true,
      headerColor: '#C3C4C2',
      resultListsColor: '#C3C4C2',
      themeColor: '#C3C4C2',
      logo: capOneLogo,
      title: 'Pay Business Bills',
    },
    links: sbbLinks,
  },
  prod: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.tabapay.com/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      backButtonUrl: 'https://myaccounts.capitalone.com/SBBank/{BACK_BUTTON_REF_ID}',
      backButtonUrlFallback: 'https://myaccounts.capitalone.com/accountSummary',
      failedToCollectSupport:
        'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702600449052-How-to-handle-unsuccessful-payments',
    },
  },
  dev: {
    services: {
      tabapay: {
        card: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        credit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html?cards=visa,mastercard',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
        debit: {
          url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioCap1.html',
          iframeHeight: [430, 380],
          iframeWidth: '100%',
        },
      },
    },
    links: {
      backButtonUrl: `https://ease-qa.clouddqtext.capitalone.com/SBBank/{BACK_BUTTON_REF_ID}`,
      backButtonUrlFallback: 'https://ease-qa.clouddqtext.capitalone.com/accountSummary',
      failedToCollectSupport:
        'https://capitalonebusinessbills.zendesk.com/hc/en-us/articles/9702600449052-How-to-handle-unsuccessful-payments',
    },
  },
});
