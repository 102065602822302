import { ApiProvider as ApiProviderV2 } from '@melio/api-client';
import { ServerConfig as ARServerConfig } from '@melio/ar-api-axios-client';
import { ServerConfig } from '@melio/javascript-sdk';
import { Logger } from '@melio/platform-logger';
import { ReactNode, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';

const MINUTE = 1000 * 60;

// Create a client
const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 5 * MINUTE,
      staleTime: 60 * MINUTE,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

setLogger({
  log: (message) => {
    Logger.log(message as never, 'log');
  },
  warn: (message) => {
    Logger.log(message as never, 'warn');
  },
  error: (error) => {
    Logger.handleException(error);
  },
});

export type ApiProviderProps = {
  accessToken?: string;
  getRequestHeaders?: () => Promise<Record<string, string>>;
  onTokenExpired?: () => Promise<unknown>;
  apiServer?: string;
  queryClient?: QueryClient;
  children?: ReactNode;
};

export const ApiProvider = ({
  apiServer,
  accessToken,
  getRequestHeaders,
  onTokenExpired,
  children,
  queryClient = defaultQueryClient,
}: ApiProviderProps) => {
  useEffect(() => {
    ARServerConfig.update({ basePath: apiServer, accessToken, getRequestHeaders, onTokenExpired });
    ServerConfig.update({ apiServer, accessToken, getRequestHeaders, onTokenExpired });
  }, [apiServer, accessToken, getRequestHeaders, onTokenExpired]);
  return (
    <ApiProviderV2
      basePath={apiServer}
      accessToken={accessToken}
      getRequestHeaders={getRequestHeaders}
      onTokenExpired={onTokenExpired}
    >
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiProviderV2>
  );
};
