import { compact, flatten, flow, isObject } from 'lodash';
import { MelioConfig } from '@melio/platform-provider';

type FeatureObject = {
  enabled: boolean;
  flagName?: string;
};

const getFlagNamesFromEntry = ([, value]: [string, Object]) => {
  const featureSetting = value as FeatureObject;
  if (!isObject(featureSetting)) {
    return [];
  }
  if (featureSetting.flagName) {
    return [featureSetting.flagName];
  }

  return extractFlagNamesFromConfigObjectRecursive(value);
};

const extractFlagNamesFromConfigObjectRecursive = (configObject: Object): string[] =>
  flow(Object.entries, (entries) => entries.map(getFlagNamesFromEntry), flatten)(configObject);

export const extractFlagNamesFromConfig = (config: MelioConfig): string[] =>
  compact(extractFlagNamesFromConfigObjectRecursive(config.settings));
