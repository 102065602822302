import { IllustrationProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { BaseErrorScreen } from '../../../base-screens';

type PageName = 'request-canceled' | 'info-already-sent' | 'something-went-wrong';

export type CollectW9FileErrorsScreenProps = {
  pageName: PageName;
  title: string;
  description: string;
  illustrationProps: IllustrationProps;
};

export const CollectW9FileErrorsScreen = forwardRef<CollectW9FileErrorsScreenProps, 'div'>(
  ({ title, description, illustrationProps, pageName, ...props }, ref) => {
    const { track } = useAnalytics();

    track('Vendor', 'View', {
      PageName: pageName,
      Intent: 'add-tax-info',
      TaxIdType: null,
    });

    return (
      <BaseErrorScreen
        title={title}
        description={description}
        withIllustration
        illustrationProps={illustrationProps}
        ref={ref}
        data-testid="collect-w9-file-error-screen"
        data-component="CollectW9FileActivity.CollectW9FileErrorsScreen"
        {...props}
      />
    );
  }
);

CollectW9FileErrorsScreen.displayName = 'CollectW9FileActivity.CollectW9FileErrorsScreen';
