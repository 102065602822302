import { Theme } from '@melio/platform-provider';

export const wafedTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/wafed/FiservLogo.svg',
    dark: 'https://platform-static.meliopayments.com/assets/wafed/FiservLogoDark.svg',
  },
  fonts: {
    primary: 'Roboto,Arial,sans-serif',
  },
  colors: {
    global: {
      brand: {
        100: '#E8F0FC',
        200: '#D1E2F9',
        700: '#186CE2',
        800: '#1356B5',
        900: '#1356B5',
      },
      informative: {
        100: '#E8F0FC',
        700: '#186CE2',
        900: '#1356B5',
      },
      warning: {
        100: '#FFF8EA',
        700: '#FFBB29',
      },
      success: {
        100: '#E6F5EB',
        700: '#008733',
        900: '#00782E',
      },
      critical: {
        100: '#FBE9EA',
        700: '#DA1E28',
        900: '#C8102E',
      },
    },
    semantic: {
      link: {
        primary: {
          rest: '{global.brand.700}',
          hover: '{global.brand.800}',
          pressed: '{global.brand.900}',
        },
      },
    },
    component: {
      pill: {
        secondaryCritical: {
          rest: {
            label: '{global.critical.700}',
          },
        },
        secondarySuccess: {
          rest: {
            label: '{global.success.600}',
          },
        },
        secondaryInformative: {
          rest: {
            label: '{global.informative.600}',
          },
        },
      },
    },
  },
  icons: {
    bank: 'https://platform-static.meliopayments.com/assets/wafed/Bank.svg',
  },
};
