import { ExternalLayout, useToast } from '@melio/penny';
import {
  DeliveryMethod,
  DeliveryMethodType,
  useOrganizationDeliveryMethods,
  useVexMyAccount,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

import { ConfirmDeliveryMethodScreen } from './screens/ConfirmDeliveryMethod/ConfirmDeliveryMethod.screen';

export const ConfirmDeliveryMethodActivity: FC<{
  contentHeaderTitle: ReactNode;
  onDone: (deliveryMethodId: string) => Promise<void>;
  onBack?: () => void;
  onError: () => void;
  deliveryMethodType: DeliveryMethodType;
  organizationId: string | undefined;
  header: ReactElement | undefined;
}> = ({ contentHeaderTitle, onDone, onBack, deliveryMethodType, organizationId, onError, header }) => {
  const { isLoading: isLoadingAccountData, error: accountDataError, data: accountData } = useVexMyAccount();
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast();
  const { formatMessage } = useMelioIntl();

  const {
    data: deliveryMethods,
    isLoading: isLoadingDms,
    error: dmsError,
  } = useOrganizationDeliveryMethods({ organizationId, options: { enabled: true } });

  const deliveryMethod = deliveryMethods?.find(({ type }) => type === deliveryMethodType);

  useEffect(() => {
    if (dmsError || accountDataError) {
      onError();
    }
  }, [dmsError, onError, accountDataError]);

  if (isLoadingDms || !deliveryMethod || !accountData || isLoadingAccountData) {
    return <ExternalLayout isLoading />;
  }

  const confirmDeliveryMethod = async () => {
    setIsSaving(true);

    try {
      await onDone(deliveryMethod.id);
    } catch (error) {
      toast({ type: 'error', title: formatMessage('vex.activities.confirmDeliveryMethod.errors.toast.title') });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ConfirmDeliveryMethodScreen
      deliveryMethod={deliveryMethod as unknown as DeliveryMethod}
      contentHeaderTitle={contentHeaderTitle}
      onDone={confirmDeliveryMethod}
      onBack={onBack}
      companyName={accountData.company.name || undefined}
      isSaving={isSaving}
      header={header}
    />
  );
};

ConfirmDeliveryMethodActivity.displayName = 'ConfirmDeliveryMethodActivity';
