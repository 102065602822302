import { merge } from 'lodash';

import { fiservConfigBase } from '@/configByPartner/fiserv.config';
import { extendDefaultConfig, PartnerConfigByEnv } from '@/configByPartner/partnerConfig.utils';

const fiservWafedConfigBase: PartnerConfigByEnv = {
  default: {
    settings: {
      newPayExperience: {
        firstTimeExperience: {
          assetType: 'image',
          imageUrl: 'https://platform-static.meliopayments.com/assets/wafed/illustrations/first-time-experience.png',
          mobileImageUrl:
            'https://platform-static.meliopayments.com/assets/wafed/illustrations/first-time-experience.png',
          videoLink: '',
        },
      },
    },
  },
  dev: {},
  prod: {},
};

export const fiservWafedConfig = extendDefaultConfig(
  merge<PartnerConfigByEnv>({}, fiservConfigBase, fiservWafedConfigBase),
);
