import { LottieProps } from '@melio/penny';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';
type Props = Omit<LottieProps, 'animation'> & { src: string; id: string };

const LazyLottie = lazy(() => import('./LazyLottie'));

function _LazyAnimation({ src, ...props }: Props) {
  const { data } = useQuery<LottieProps['animation']>({
    queryKey: ['animation', src],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    queryFn: () => {
      import('./LazyLottie');
      return fetch(src).then((res) => res.json());
    },
    suspense: true,
  });
  return <LazyLottie {...props} animation={data} />;
}

export function LazyAnimation({ src: src, id, ...props }: Props) {
  return (
    <ErrorBoundary fallback={<div></div>}>
      <Suspense fallback={<div></div>}>
        <_LazyAnimation {...props} src={src} id={id} />
      </Suspense>
    </ErrorBoundary>
  );
}
