import type { PartnerIcons } from '@/partnersConfig.types';

export const CitizensIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/citizens/fav.icon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/melio.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/logo.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/error.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/emptyBills.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/noBillsFound.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/noVendorsFound.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/verifyEmail.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  ExpiredSessionIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/expiredSession.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/empty.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/citizens/addBill.icon.svg',
  },
};
