import { useCallback, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { PartnerName } from '@melio/platform-api';
import { SessionConfigProvider } from '@melio/vex-activities';
import { useAuth, useExtractTokenIdentifierFromUrl } from '@melio/vex-domain';

import { BASE_PATH } from '@/api/consts';
import { ConfigurationProvider } from './ConfigurationProvider.component';

type Props = {
  partnerName: PartnerName;
};

export const VexConfigurationProvider = ({ partnerName }: Props) => {
  const tokenIdentifier = useExtractTokenIdentifierFromUrl();
  const navigate = useNavigate();

  const DEFAULT_SESSION_EXPIRED_ROUTE = '/expired-session';
  const [sessionExpiredRoute, setSessionExpiredRoute] = useState<string>(DEFAULT_SESSION_EXPIRED_ROUTE);

  const onUnauthorized = useCallback(() => {
    navigate(sessionExpiredRoute, { replace: true });
  }, [navigate, sessionExpiredRoute]);

  const { accessToken, setAccessTokens, refreshAccessToken } = useAuth({
    tokenIdentifier,
    onUnauthorized,
    apiBasePath: BASE_PATH,
  });

  return (
    <ConfigurationProvider
      partnerName={partnerName}
      accessToken={accessToken}
      onTokenExpired={refreshAccessToken}
      disablePermissions
    >
      <SessionConfigProvider
        setAccessTokens={setAccessTokens}
        overrideSessionExpiredRoute={setSessionExpiredRoute}
        accessToken={accessToken}
      >
        <Outlet />
      </SessionConfigProvider>
    </ConfigurationProvider>
  );
};
