/* eslint-disable react-hooks/exhaustive-deps */
import { InitOptions, SpaceApi } from '@usersnap/browser';
import React, { useContext, useEffect, useState } from 'react';

import { useConfig } from './ConfigProvider';
import { FeedbackProviderProps } from './types';

export const FeedbackContext = React.createContext<SpaceApi | null>(null);

export const FeedbackProvider = ({ children, feedbackWidgetProps }: FeedbackProviderProps) => {
  const { organizationId, userEmail, userId, isFeedbackWidgetOn, userCreatedAt } = feedbackWidgetProps || {};
  const [feedbackApi, setFeedbackApi] = useState<SpaceApi | null>(null);
  const { services } = useConfig();

  const getInitData = () => {
    const initUser = {
      userId,
      email: userEmail,
    };
    const initCustom = {
      userId,
      organizationId,
      userCreatedAt,
      fullStorySessionURL: window.FS ? window.FS.getCurrentSessionURL?.() : undefined,
    };

    return {
      user: initUser,
      custom: initCustom,
    } as InitOptions;
  };

  useEffect(() => {
    let usersnapApi: SpaceApi | null = null;
    const script = document.createElement('script');
    if (isFeedbackWidgetOn) {
      const { user, custom } = getInitData();

      window.onUserSnapLoad = async function (api: SpaceApi) {
        await api.init({ user, custom });
        usersnapApi = api;
        setFeedbackApi(api);
      };
      script.defer = true;
      script.src = `https://widget.usersnap.com/global/load/${services.userSnap.spaceId}?onload=onUserSnapLoad`;
      document.getElementsByTagName('head')[0]?.appendChild(script);
    }
    return () => {
      if (usersnapApi) {
        void usersnapApi.destroy();
      }
      script.remove();
    };
  }, [feedbackWidgetProps]);

  return <FeedbackContext.Provider value={feedbackApi}>{children}</FeedbackContext.Provider>;
};

export function useFeedbackApi() {
  return useContext(FeedbackContext);
}
