import { Theme } from '@melio/platform-provider';

export const ftiTheme: Theme = {
  logos: {
    dark: 'https://platform-static.meliopayments.com/assets/melio/MelioLogoDark.svg',
    light: 'https://platform-static.meliopayments.com/assets/melio/MelioLogo.svg',
  },
  colors: {
    global: {
      brand: {
        900: '#000F85',
        800: '#082E9E',
        700: '#285CE5',
        600: '#759FFF',
        200: '#98BDFF',
        100: '#B7D1FF',
      },
      neutral: {
        400: '#D9DEE8',
        600: '#8B95A9',
      },
    },
  },
};
