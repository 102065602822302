import { Link } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentUpgradeOfferOptionEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useLocation } from '@melio/platform-utils';

import { ANALYTICS_CONTEXT, mapUpgradeOfferToAnalyticsOffer } from '../consts';

type UseFailedScreenProps = {
  isPaymentFailed: boolean;
  isUpsellOfferNotAvailable: boolean;
  isUnauthorized: boolean;
  offerType: PaymentUpgradeOfferOptionEnum | null;
};

const getAnalyticsErrorMessage = ({
  isPaymentFailed,
  isUpsellOfferNotAvailable,
  isUnauthorized,
}: {
  isPaymentFailed: boolean;
  isUpsellOfferNotAvailable: boolean;
  isUnauthorized: boolean;
}) => {
  if (isUnauthorized) {
    return 'token expired';
  }
  if (isUpsellOfferNotAvailable) {
    return 'upsell offer not available';
  }
  if (isPaymentFailed) {
    return 'payment failed to deliver';
  }
  return 'server error';
};

export const useFailedScreen = ({
  isPaymentFailed,
  isUnauthorized,
  isUpsellOfferNotAvailable,
  offerType,
}: UseFailedScreenProps) => {
  const { track } = useAnalytics();
  const { pathname } = useLocation();
  const { formatMessage } = useMelioIntl();
  const { helpCenterUrl } = useConfig().settings;

  const isOnSuccessPath = pathname.endsWith('success');
  const commonAnalyticsProps = {
    PageName: 'payment-was-unable-to-delivered',
    DeliveryMethodChosen: offerType ? mapUpgradeOfferToAnalyticsOffer[offerType] || '' : 'none',
  };

  const supportLink = (
    <Link
      newTab
      href={helpCenterUrl}
      label={formatMessage('activities.paymentUpgrade.screens.paymentUpgradeFailed.contactSupport')}
      onClick={() => {
        track(ANALYTICS_CONTEXT, 'Click', {
          ...commonAnalyticsProps,
          Intent: 'contact-support',
          Cta: 'contact-support',
        });
      }}
    />
  );

  const getFailureDescriptionMessage = (): string => {
    if (isUnauthorized) {
      if (isOnSuccessPath) {
        return formatMessage('vex.activities.paymentUpgrade.error.paymentCompletedError');
      } else {
        return formatMessage('vex.activities.paymentUpgrade.error.returnToEmailError');
      }
    }
    if (isUpsellOfferNotAvailable) {
      return formatMessage('activities.paymentUpgrade.screens.paymentUpgradeFailed.offerNotAvailable', {
        contactSupport: supportLink,
      });
    }
    if (isPaymentFailed) {
      return formatMessage('activities.paymentUpgrade.screens.paymentUpgradeFailed.paymentFailed', {
        offerType,
        contactSupport: supportLink,
      });
    }
    return formatMessage('activities.paymentUpgrade.screens.paymentUpgradeFailed.serverError');
  };

  return {
    description: getFailureDescriptionMessage(),
    isSuccessVariant: isUnauthorized && isOnSuccessPath,
    pageViewAnalyticProperties: {
      ...commonAnalyticsProps,
      StatusType: 'Failure',
      ErrorType: getAnalyticsErrorMessage({ isPaymentFailed, isUpsellOfferNotAvailable, isUnauthorized }),
    },
  };
};
