/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAnalytics } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { FeatureFlags } from '@melio/platform-feature-flags';
import { featureFlags } from '@melio/shared-web';

import { usePartnerConfig } from '@/hooks/partners';
import { extractFlagNamesFromConfig } from '@/hooks/partners/extractFlagNamesFromConfig';
import { identifyFeatureFlags } from '@/utils/featureFlagUtils';
import { getEnvironmentName } from '../utils/url.utils';

const environmentName = getEnvironmentName();

export const useFeatureFlagsInit = (appAccessToken: string | undefined, currentPartnerName?: PartnerName) => {
  const {
    partnerConfig: { config },
    partnerName,
  } = usePartnerConfig(currentPartnerName);
  const { identify, track } = useAnalytics();

  const trackVariant = React.useCallback(
    (variantName: string, value: unknown) => {
      track('FeatureFlagVariant', 'Viewed', {
        event: variantName,
        properties: { variant: value, name: variantName },
      });
    },
    [track],
  );

  const relevantFlags = [...extractFlagNamesFromConfig(config), ...Object.values(FeatureFlags)];
  const initFeatureFlags = React.useCallback(async () => {
    const clientId =
      window.localStorage.getItem('feature-flags-client-id-override') || config.featureFlagProvider.clientId;
    await featureFlags.defaultClient.initialize({
      clientId,
      trackVariant,
      channelName: partnerName,
      environmentName,
      relevantFlags,
    });
  }, [config]);

  React.useEffect(() => {
    if (config.featureFlagProvider.shouldLoad) {
      initFeatureFlags();
    }
  }, [config]);

  React.useEffect(() => {
    void identifyFeatureFlags({ identify, partnerName, appAccessToken, environmentName });
  }, [appAccessToken]);
};

export const useAnonymousAppFeature = <T>(
  flagKey: FeatureFlags,
  defaultValue: T,
  options?: featureFlags.UseFeatureOptions,
) => featureFlags.useAnonymousFeature(flagKey, defaultValue, options);

const DEFAULT_IS_LOADING_LIMITATION_MS = 2000;

export const useAnonymousFeatureTimeout = <T>(
  flagKey: FeatureFlags,
  defaultValue: T,
  options?: featureFlags.UseFeatureOptions,
  timeout: number = DEFAULT_IS_LOADING_LIMITATION_MS,
) => {
  const [flagValue, isLoading] = featureFlags.useAnonymousFeature(flagKey, defaultValue, options);
  const [isFeatureFlagLoading, setIsFeatureFlagLoading] = useState<boolean>(isLoading);

  useEffect(() => {
    const resetLoading = setTimeout(() => {
      setIsFeatureFlagLoading(false);
    }, timeout);

    return () => {
      clearTimeout(resetLoading);
    };
  }, []);

  return { flagValue, isLoading: isLoading && isFeatureFlagLoading };
};
