import { ThemeOptions } from '@melio/penny';

import { MelioIcons } from '@/cl/icons/melio';

// off-the-shelf partners that we'll eventually move to store the config in db
export const payPalTheme: ThemeOptions = {
  logos: {
    dark: 'https://platform-static.meliopayments.com/assets/paypal/PayPalLogo.svg',
    light: 'https://platform-static.meliopayments.com/assets/paypal/PayPalLogo.svg',
  },
  colors: {
    global: {
      brand: {
        100: '#faf8f5',
        200: '#009cde',
        300: '#009cde',
        400: '#009cde',
        500: '#003087',
        600: '#003087',
        700: '#003087',
        800: '#012169',
        900: '#012169',
        1000: '#012169',
      },
    },
  },
  // @ts-ignore
  borders: {
    selection: {
      selectedBrand: '2px solid #003087',
    },
  },
};

export const PayPalIcons = {
  ...MelioIcons,
  Favicon: '/partners/paypal/assets/favicon.png',
  SmallLogoIcon: undefined,
  LogoIcon: {
    small: '/partners/paypal/assets/paypal-logo-desktop.svg',
    extraSmall: '/partners/paypal/assets/paypal-logo-mobile.svg',
  },
};
