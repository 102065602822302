import { useUserPreference } from '@melio/platform-api';

const VIP_USER_PREFERENCE_KEY = 'vipWelcomeModal';

export const useVipWelcomeModalPreference = (options?: { enabled?: boolean }) => {
  const { enabled = true } = options ?? {};
  const {
    data: vipWelcomeModalUserPreference,
    create: createUserPreference,
    isFetching: isVipWelcomeModalPreferenceFetching,
    isMutating: isVipWelcomeModalPreferenceMutating,
    isLoading: isVipWelcomeModalPreferenceLoading,
  } = useUserPreference({
    id: VIP_USER_PREFERENCE_KEY,
    enabled,
  });

  const setVipWelcomeModalPreferenceViewed = () =>
    createUserPreference({ userPreference: { key: VIP_USER_PREFERENCE_KEY, value: 'true' } });

  return {
    vipWelcomeModalUserPreference,
    isVipWelcomeModalPreferenceFetching,
    isVipWelcomeModalPreferenceMutating,
    isVipWelcomeModalPreferenceLoading,
    setVipWelcomeModalPreferenceViewed,
    isVipWelcomeModalViewed: vipWelcomeModalUserPreference ? vipWelcomeModalUserPreference.value === 'true' : undefined,
  };
};
