// eslint-disable-next-line no-restricted-imports
import { DefaultOptions, QueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { HttpStatusCodes } from '@/HttpEnums';

const typedDefaultOptions: DefaultOptions<AxiosError & { rawError?: AxiosError }> = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 3000000,
    retry: (count, error) => {
      switch (error.response?.status || error.rawError?.response?.status) {
        case HttpStatusCodes.notFound:
        case HttpStatusCodes.unauthorized:
        case HttpStatusCodes.Forbidden:
          return false;
        default:
          return count < 1;
      }
    },
  },
};

export const queryClient = new QueryClient({
  defaultOptions: typedDefaultOptions as DefaultOptions<unknown>, // TS errors otherwise...
});
