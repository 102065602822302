import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import jwtDecode from 'jwt-decode';
import { noop } from 'lodash';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Engagement } from './Engagement';
import {
  AccessTokenPayloadType,
  BrazeSDKType,
  EngagementContextType,
  EngagementModalButton,
  ModalMessage,
  UserEngagement,
} from './types';

const defaultContextValue = {
  logEngagementCustomEvent: noop,
  engagementModalSubscriptionEventHandler: noop,
  logEngagementModalImpression: noop,
  resetEngagementModal: noop,
  logEngagementModalClick: () => false,
  logEngagementModalButtonClick: () => false,
};

export const EngagementContext = createContext<EngagementContextType>(defaultContextValue);

export const useEngagement = () => useContext<EngagementContextType>(EngagementContext);

export const EngagementProvider = ({
  accessToken,
  onMessageHandler,
  service,
  children,
  config,
}: {
  accessToken?: string;
  children: ReactNode;
  onMessageHandler?: (inAppMessage: ModalMessage) => void;
  service?: BrazeSDKType;
  config: { production?: boolean };
}) => {
  const [modalMessage, setModalMessage] = useState<ModalMessage>();
  const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState(true);
  const [engagement, setEngagement] = useState<Engagement>();
  const [isEngagementProvider] = useDevFeature(FeatureFlags.IsEngagementProvider, false);

  if (isEngagementProvider && isFirstTimeLoaded && !engagement && accessToken) {
    const decodeAccessToken = jwtDecode<AccessTokenPayloadType>(accessToken);

    const { user, organization, partnerName } = decodeAccessToken;

    const engagementUser: UserEngagement = {
      id: user?.id,
      contactInfo: {
        contactFirstName: organization?.contactFirstName,
        contactLastName: organization?.contactLastName,
      },
      email: user?.email,
      partnerName,
    };

    const messageHandler = onMessageHandler
      ? onMessageHandler
      : (modalMessage: ModalMessage) => {
          setModalMessage(modalMessage);
        };

    const engagement = new Engagement(engagementUser, messageHandler, config, service);

    setEngagement(engagement);
    setIsFirstTimeLoaded(false);
  }

  const contextValue: EngagementContextType =
    isEngagementProvider && engagement?.isInitialized
      ? {
          logEngagementCustomEvent: (eventName: string) => engagement.logCustomEvent(eventName),
          engagementModalSubscriptionEventHandler: (cb: (modalMessage?: ModalMessage) => void): void =>
            cb(modalMessage),
          logEngagementModalImpression: (inAppMessage?: ModalMessage) =>
            engagement.logInAppMessageImpression(inAppMessage),
          resetEngagementModal: () => setModalMessage(undefined),
          logEngagementModalClick: (inAppMessage: ModalMessage) => engagement.logInAppMessageClick(inAppMessage),
          logEngagementModalButtonClick: (button: EngagementModalButton, inAppMessage: ModalMessage) =>
            engagement.logInAppMessageButtonClick(button, inAppMessage),
        }
      : defaultContextValue;
  return <EngagementContext.Provider value={contextValue}>{children}</EngagementContext.Provider>;
};
