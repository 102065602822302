import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryMethodType, useVexMyAccount, useVexMyOrganizations } from '@melio/platform-api';
import { ReactElement, ReactNode } from 'react';

import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { AcceptPaymentWithBankAccountWizard } from './AcceptPaymentWithBankAccount.wizard';

type AcceptPaymentWithBankAccountActivityProps = {
  contentHeaderTitle: ReactNode;
  onAcceptPayment: (params: AcceptPaymentWithBankAccountDeliveryMethodParams) => Promise<void>;
  cancelUrlFallback: string;
  payorOrganizationName: string;
  paymentId: string;
  header: ReactElement | undefined;
};

export const AcceptPaymentWithBankAccountActivity = withAnalyticsContext<AcceptPaymentWithBankAccountActivityProps>(
  ({
    contentHeaderTitle,
    onAcceptPayment,
    cancelUrlFallback,
    payorOrganizationName,
    paymentId,
    setAnalyticsProperties,
    header,
  }) => {
    setAnalyticsProperties({ PaymentId: paymentId, DeliveryMethodType: DeliveryMethodType.BankAccount });

    const {
      isGuest,
      isLoading: isLoadingAccountData,
      isFetching: isFetchingAccountData,
      refetch: refetchAccountData,
      data: accountData,
    } = useVexMyAccount();

    const { data: userOrganizations, isLoading: isLoadingUserOrganizations } = useVexMyOrganizations({
      enabled: !isGuest,
    });

    if (isLoadingAccountData || isLoadingUserOrganizations) {
      return <ExternalLayout isLoading />;
    }

    return (
      <AcceptPaymentWithBankAccountWizard
        onAcceptPayment={onAcceptPayment}
        cancelUrlFallback={cancelUrlFallback}
        contentHeaderTitle={contentHeaderTitle}
        organizationId={accountData?.organizationId}
        payorOrganizationName={payorOrganizationName}
        onSwitchOrganization={refetchAccountData}
        isFetchingAccountData={isFetchingAccountData}
        organizations={userOrganizations}
        header={header}
      />
    );
  }
);
