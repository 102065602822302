import { createContext, PropsWithChildren, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from 'react-query';

import { LocalizationProvider, LocalizationProviderProps } from './LocalizationProvider';
import { BaseTranslations, Translations } from './types';

export const messageOverrideCacheKey = (src = '') => ['messages', src];
export const LocalizatioMetaContext = createContext<{ isLoading: boolean }>({ isLoading: true });
export type AsyncLocalizationProviderProps<T extends BaseTranslations> = Omit<
  LocalizationProviderProps<T>,
  'translations'
> & { translationsSrc?: string };
export function AsyncLocalizationProvider<T extends BaseTranslations>({
  translationsSrc,
  ...props
}: PropsWithChildren<AsyncLocalizationProviderProps<T>>) {
  const { data: overrides, isLoading } = useQuery<Translations<T>>(
    messageOverrideCacheKey(translationsSrc),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetch(translationsSrc!).then((res) => res.json()),
    {
      enabled: !!translationsSrc,
    }
  );

  return (
    <LocalizatioMetaContext.Provider value={useMemo(() => ({ isLoading }), [isLoading])}>
      <LocalizationProvider {...props} translations={overrides} />
    </LocalizatioMetaContext.Provider>
  );
}

AsyncLocalizationProvider.displayName = 'AsyncLocalizationProvider';
