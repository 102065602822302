import { Theme } from '@melio/platform-provider';

import { MelioIcons } from '@/cl/icons/melio';

// off-the-shelf partners that we'll eventually move to store the config in db
export const optumBankTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/optum-bank/optum-bank-logo.icon.svg',
    dark: 'https://platform-static.meliopayments.com/assets/optum-bank/optum-bank-logo.icon.svg',
  },
  colors: {
    global: {
      brand: {
        100: '#e6e6e6',
        200: '#d0edfe',
        300: '#d0edfe',
        400: '#d0edfe',
        500: '#316BBE',
        600: '#316BBE',
        700: '#316BBE',
        800: '#00396C',
        900: '#00396C',
        1000: '#00396C',
      },
    },
  },
  // @ts-ignore
  borders: {
    selection: {
      selectedBrand: '2px solid #FF612B',
    },
  },
};

export const OptumBankIcons = {
  ...MelioIcons,
  SmallLogoIcon: undefined,
  LogoIcon: {
    small: '/partners/optum-bank/assets/optum-bank-icon-small.icon.svg',
    extraSmall: '/partners/optum-bank/assets/optum-bank-icon-extra-small.icon.svg',
  },
};
