import { Button, Container, Group, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { MessageKey } from '@melio/platform-i18n';
import { FormattedMessage, useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

type JoinMelioWidgetProps = {
  titleId?: MessageKey;
  contentIdWithLearnMoreLink?: MessageKey;
};

export const JoinMelioWidget = forwardRef<JoinMelioWidgetProps>(
  ({ titleId, contentIdWithLearnMoreLink, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const {
      settings: { registerToMelioGetPaid },
    } = useConfig();

    const handleJoinButtonClick = () => {
      track('Vendor', 'Click', { Cta: 'join-melio-free' });
    };

    return (
      <Container paddingX="xl" paddingY="m">
        <Group
          data-testid="join-melio"
          data-component="JoinMelioWidget"
          variant="vertical"
          spacing="l"
          {...props}
          ref={ref}
        >
          <Group spacing="s" variant="vertical">
            <Text as="h2" textStyle="heading3Semi" data-testid="join-melio-headline">
              {formatMessage(
                titleId ?? 'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.joinMelio.title'
              )}
            </Text>
            <Text data-testid="join-melio-content">
              <FormattedMessage
                id={
                  contentIdWithLearnMoreLink ??
                  'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.joinMelio.content'
                }
                values={{
                  link: (
                    <Link
                      data-testid="join-melio-learn-more-link"
                      href="https://meliopayments.com/accounts-receivable/"
                      label={formatMessage(
                        'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.joinMelio.linkText'
                      )}
                      newTab
                    />
                  ),
                }}
              />
            </Text>
          </Group>
          <Button
            data-testid="join-melio-free"
            size="large"
            isFullWidth
            link={{
              href: registerToMelioGetPaid,
              target: '_parent',
            }}
            onClick={handleJoinButtonClick}
            label={formatMessage(
              'activities.addDeliveryMethodByPayee.screens.deliveryMethodAddedSuccessfully.joinMelio.buttonLabel'
            )}
          />
        </Group>
      </Container>
    );
  }
);

JoinMelioWidget.displayName = 'JoinMelioWidget';
