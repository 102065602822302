import {
  ErrorPage as ErrorPageBase,
  ErrorPageParams as ErrorPageBaseParams,
} from '@/cl/components/ErrorPage/ErrorPage.component';
import { usePartnerConfig } from '@/hooks/partners';

type ErrorPageParams = Omit<ErrorPageBaseParams, 'icon'>;

export const ErrorPage = ({ ...params }: ErrorPageParams) => {
  const { partnerConfig } = usePartnerConfig();

  return <ErrorPageBase icon={partnerConfig.icons.ErrorIcon.small} {...params} />;
};
