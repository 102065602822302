import {
  PostAuthProviderCallbackCapitalOneRequest,
  PostAuthProviderCallbackCloverRequest,
  PostAuthProviderCallbackFiservRequest,
  PostAuthProviderCallbackMelioRequest,
  PostAuthProviderCallbackPaypalRequest,
  PostAuthProviderCallbackStandardRequest,
  PostAuthSsoRequest,
  PostResolveEmailVerificationCodeRequest,
  PostSendEmailVerificationCodeRequest,
} from '@melio/platform-api';

import { authApiClient } from './apiClients';

export const authProviderCallbackCapitalOne = ({
  token,
  orgId,
  target,
  targetId,
  entryPoint,
  partnerName,
  userRole,
}: PostAuthProviderCallbackCapitalOneRequest) =>
  authApiClient
    .postAuthProvidersCapitaloneCallback({
      postAuthProviderCallbackCapitalOneRequest: {
        token,
        orgId,
        target,
        targetId,
        entryPoint,
        partnerName,
        userRole,
      },
    })
    .then((res) => res.data.data);

export const authProviderCallbackClover = ({
  token,
  orgId,
  target,
  userId,
  targetId,
  entryPoint,
}: PostAuthProviderCallbackCloverRequest) =>
  authApiClient
    .postAuthProvidersCloverCallback({
      postAuthProviderCallbackCloverRequest: {
        token,
        orgId,
        userId,
        target,
        targetId,
        entryPoint,
      },
    })
    .then((res) => res.data.data);

export const authProviderCallbackMelio = ({
  token,
  orgId,
  target,
  targetId,
  entryPoint,
}: PostAuthProviderCallbackMelioRequest) =>
  authApiClient
    .postAuthProvidersMelioCallback({
      postAuthProviderCallbackMelioRequest: {
        token,
        orgId,
        target,
        targetId,
        entryPoint,
      },
    })
    .then((res) => res.data.data);

export const authProviderCallbackStandardProvider = ({
  token,
  partnerName,
  target,
  targetId,
  entryPoint,
}: PostAuthProviderCallbackStandardRequest) =>
  authApiClient
    .postAuthProvidersStandardCallback({
      postAuthProviderCallbackStandardRequest: {
        token,
        target,
        targetId,
        entryPoint,
        partnerName,
      },
    })
    .then((res) => res.data.data);

export const authProviderCallbackPaypalProvider = ({
  token,
  target,
  targetId,
  entryPoint,
}: PostAuthProviderCallbackPaypalRequest) =>
  authApiClient
    .postAuthProvidersPaypalCallback({
      postAuthProviderCallbackPaypalRequest: {
        token,
        target,
        targetId,
        entryPoint,
      },
    })
    .then((res) => res.data.data);

export const authProviderCallbackFiserv = ({ token, partnerName }: PostAuthProviderCallbackFiservRequest) =>
  authApiClient
    .postAuthProvidersFiservCallback({
      postAuthProviderCallbackFiservRequest: {
        token,
        partnerName,
      },
    })
    .then((res) => res.data.data);

export const authProviderLogoutMelio = () => authApiClient.postAuthProvidersMelioLogout().then((res) => res.data);

export const oAuthRegister = ({
  partnerName,
  providerData,
  accountInfo,
  selectedOrgId,
  userConsent,
}: PostAuthSsoRequest) =>
  authApiClient
    .postAuthOauth2Register({
      postAuthSsoRequest: {
        partnerName,
        providerData,
        accountInfo,
        selectedOrgId,
        userConsent,
      },
    })
    .then((res) => res.data.data);

export const sendVerificationEmailCode = ({ email, partnerName, melioOnly }: PostSendEmailVerificationCodeRequest) =>
  authApiClient.postAuthSendEmailValidationCode({
    postSendEmailVerificationCodeRequest: {
      email,
      partnerName,
      melioOnly,
    },
  });

export const resolveVerificationEmailCode = ({ email, code }: PostResolveEmailVerificationCodeRequest) =>
  authApiClient.postAuthResolveEmailValidationCode({
    postResolveEmailVerificationCodeRequest: {
      email,
      code,
    },
  });

export const authApiGetIntuitConnectLink = async ({
  successReturnUrl,
  errorReturnUrl,
  isAccountant,
}: {
  successReturnUrl: string;
  errorReturnUrl: string;
  isAccountant: boolean;
}) => {
  try {
    const {
      data: {
        data: { token, link, loginWithTokenLink },
      },
    } = await authApiClient.getAuthProvidersIntuitLink({
      successReturnUrl,
      errorReturnUrl,
      isAccountant,
    });

    return { token, link, loginWithTokenLink };
  } catch (error) {
    console.error('error in intuit/link');
    throw error;
  }
};

export const getAuthTokenFromActionToken = async (token: string) =>
  authApiClient
    .postAuthActionTokenExchange({
      postAuthActionTokenExchangeRequest: {
        token,
      },
    })
    .then((res) => res.data.data);

export const createAccountAccessToken = (accountId: string) =>
  authApiClient
    .postAuthToken({
      postAuthTokenRequest: { accountId },
    })
    .then((res) => res.data.data);

export const switchOrganization = (organizationId: string) =>
  authApiClient
    .postAuthSwitchOrganization({
      id: organizationId,
    })
    .then((res) => res.data.data);
