import { extendDefaultConfig } from '@/configByPartner/partnerConfig.utils';

export const standardProviderConfig = extendDefaultConfig({
  default: {
    settings: {
      isEmbeddedExperience: true,
      partnerProductName: 'Pay Bills',
      fundingSourcePolicy: {
        'flex-account': {
          order: 0,
          fundingSourceGroup: 'flex-account',
          read: false,
          write: false,
        },
        'bank-account': {
          order: 1,
          fundingSourceGroup: 'bank-account',
          read: true,
          write: true,
        },
        credit: {
          order: 2,
          fundingSourceGroup: 'card',
          read: true,
          write: true,
        },
        debit: {
          order: 3,
          fundingSourceGroup: 'card',
          read: true,
          write: true,
        },
      },
    },
  },
  dev: {},
  prod: {},
});
