export type PartnerLocalStorageParams = {
  partnerName: string;
};

export enum LocalStorageKeys {
  accessToken = 'access_token',
  oAuthToken = 'oauth_token',
  refreshToken = 'refresh_token',
  backButtonReferenceId = 'back_button_ref_id',
  googleAccessToken = 'google_access_token',
  appRedirectData = 'app_redirect_data',
  freeAchPromotionModalSeen = 'free_ach_promotion_modal_seen',
  partnerName = 'partner_name',
  basePath = 'base_path',
}

export type PartnerLocalStorageKey = `${string}:${LocalStorageKeys}`;

export type LocalStorage = {
  setItem: (key: LocalStorageKeys, value: string) => void;
  removeItem: (key: LocalStorageKeys) => void;
  getItem: (key: LocalStorageKeys) => string | null;
  getObject: <T>(key: LocalStorageKeys) => Partial<T>;
  setObject: <T>(key: LocalStorageKeys, value: T) => void;
};
