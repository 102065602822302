import { merge } from 'lodash';

export type ServerConfigProps = {
  apiServer: string;
  orgId: number;
  accessToken: string;
  getRequestHeaders?: () => Promise<Record<string, string>>;
  onTokenExpired?: () => Promise<unknown>;
};

const defaultConfig: ServerConfigProps = {
  apiServer: '',
  orgId: 0,
  accessToken: '',
};

export class ServerConfig {
  private static config: ServerConfigProps = defaultConfig;

  static update(params: Partial<ServerConfigProps>) {
    merge(this.config, params);
  }

  static get apiServer() {
    return this.config.apiServer;
  }

  static get orgId() {
    return this.config.orgId;
  }

  static get accessToken() {
    return this.config.accessToken;
  }

  static getRequestHeaders = () => this.config.getRequestHeaders?.() ?? Promise.resolve({});

  static get onTokenExpired() {
    return this.config.onTokenExpired;
  }
}
