import { PartnerIcons } from '@/partnersConfig.types';

export const MelioIcons: PartnerIcons = {
  Favicon: 'https://platform-static.meliopayments.com/assets/system/defaultFavicon.svg',
  MelioIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/melio-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/system/melio-extra-small.icon.svg',
  },
  LogoIcon: {
    small: 'https://platform-static.meliopayments.com/assets/system/melio-small.icon.svg',
    extraSmall: 'https://platform-static.meliopayments.com/assets/system/melio-extra-small.icon.svg',
  },
  ErrorIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  EmptyBillsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  EmptyVendorsIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/emptyVendors.icon.svg',
  },
  NoBillsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  NoVendorsFoundIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  VerifyEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  TaxSummaryIcon: {
    small: 'https://platform-static.meliopayments.com/assets/default/taxSummary.icon.svg',
  },
  BackToPartnerIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  AddBillIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  StartNewExperienceIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
  TryDifferentEmailIcon: {
    small: 'https://platform-static.meliopayments.com/assets/melio/empty.icon.svg',
  },
};
