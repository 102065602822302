/* eslint-disable @typescript-eslint/restrict-template-expressions, no-console */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';
import { identity, merge } from 'lodash';
import { stringify } from 'qs';

import { ServerConfig } from './ServerConfig';
import { handleTimeoutRequest } from './utils/handleTimeoutReqeust';

type RequestConfig = InternalAxiosRequestConfig & { _retried?: boolean };

export type HttpResponse<T = unknown, D = unknown> = AxiosResponse<T, D>;

type SearchObject = object & { search?: object };

const parseParams = (params?: SearchObject) => ({
  ...params,
  ...(params?.search && { search: JSON.stringify(params.search) }),
});

export class HTTPClient {
  private service: AxiosInstance;
  private refreshTokenRequest: Promise<unknown> | void = void 0;

  constructor() {
    this.service = axios.create();

    this.service.interceptors.request.use(async (config: InternalAxiosRequestConfig) =>
      merge({}, config, {
        baseURL: ServerConfig.apiServer,
        headers: {
          'Cache-Control': 'no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate',
          'Idempotency-Key': '11111',
          ...(ServerConfig.accessToken ? { Authorization: `Bearer ${ServerConfig.accessToken}` } : {}),
          ...(await ServerConfig.getRequestHeaders()),
        },
        paramsSerializer: (params: SearchObject) => stringify(parseParams(params)),
      })
    );

    // middleware to refresh token on 401 errors
    this.service.interceptors.response.use(identity, (error: AxiosError) => {
      const config: RequestConfig = error.config as RequestConfig;
      if (error.response?.status === 401) {
        console.info(
          `Response unauthorized; accessToken defined: ${!!ServerConfig.accessToken}; onTokenExpired defined: ${!!ServerConfig.onTokenExpired}`
        );
      }
      if (
        error.response?.status === 401 &&
        !config._retried &&
        !!ServerConfig.accessToken &&
        !!ServerConfig.onTokenExpired
      ) {
        // make sure we only try to refresh the token on one failed request (when we have more than one)
        if (!this.refreshTokenRequest) {
          // make sure we only try to refresh the token once per request
          config._retried = true;
          // assign a promise to be treated as a "queue" mechanism so all
          // 401 errors will listen to it and retry when it resolves
          console.info('Refreshing token - onTokenExpired');
          this.refreshTokenRequest = ServerConfig.onTokenExpired()
            .then(() => {
              console.info('onTokenExpired resolved');
              return new Promise<void>((resolve) => {
                // clear `this.refreshTokenRequest` in the next event loop
                // to allow async state to update before retrying
                setTimeout(() => resolve((this.refreshTokenRequest = void 0)), 1);
              });
            })
            .catch((e) => {
              console.info(`onTokenExpired rejected ${e}`);
              return new Promise<void>((_resolve, reject) => {
                // clear `this.refreshTokenRequest` in the next event loop
                // to allow async state to update before retrying
                setTimeout(() => {
                  this.refreshTokenRequest = void 0;
                  reject();
                }, 1);
              });
            });
        }
        return this.refreshTokenRequest.then(() => this.service(config));
      }
      throw error;
    });
    this.service.interceptors.response.use(identity, (error: AxiosError) => {
      handleTimeoutRequest(error);

      throw error;
    });
  }

  get = <T>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>> =>
    this.service.get<T, HttpResponse<T>>(url, config).catch(handleRequestError);

  post = <T, D = T>(url: string, params?: D, config?: AxiosRequestConfig): Promise<HttpResponse<T>> =>
    this.service.post<T, HttpResponse<T>, D>(url, params, config).catch(handleRequestError);

  delete = (url: string, config?: AxiosRequestConfig): Promise<HttpResponse<void>> =>
    this.service.delete<void, HttpResponse<void>>(url, config).catch(handleRequestError);

  put = <T, D = T>(url: string, params?: D, config?: AxiosRequestConfig): Promise<HttpResponse<T>> =>
    this.service.put<T, HttpResponse<T>, D>(url, params, config).catch(handleRequestError);

  patch = <T>(url: string, params = {}, config?: AxiosRequestConfig): Promise<HttpResponse<T>> =>
    this.service.patch<T, HttpResponse<T>>(url, params, config).catch(handleRequestError);
}

type ValidationError = AxiosError<{ error: { message: string; code: number; data: string[] } }>;

function isValidationError(error: unknown): error is ValidationError {
  return isAxiosError(error) && !!(error as ValidationError).response?.data.error;
}

function handleRequestError(error: unknown) {
  return Promise.reject(isValidationError(error) ? { ...error.response?.data.error, rawError: error } : error);
}

export const httpClient = new HTTPClient();
