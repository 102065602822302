import { Box } from '@chakra-ui/react';
import { Container, Group, Layout } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { useIsMobile } from '../../hooks';

export type GuestLayoutProps = {
  isLoading?: boolean;
  footer?: React.ReactNode;
};

export const GuestLayout = forwardRef<GuestLayoutProps>(({ isLoading, children, footer, ...props }, ref) => {
  const isMobile = useIsMobile();

  return (
    <Box backgroundColor="global.neutral.300" height="100%">
      <Layout
        backgroundColor="default"
        isLoading={isLoading}
        paddingContent={isMobile ? 'none' : 'xxl'}
        data-layout={GuestLayout.displayName}
        maxWidth="600px"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" alignItems="center" spacing="l">
          <Container border={isMobile ? 'none' : 'regular'} backgroundColor="white" paddingX="l" paddingY="l">
            {children}
          </Container>
          <Container backgroundColor="white" border={isMobile ? 'none' : 'regular'} paddingX="l" paddingY="l">
            {footer}
          </Container>
        </Group>
      </Layout>
    </Box>
  );
});

GuestLayout.displayName = 'GuestLayout';
