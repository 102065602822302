import { Box } from '@chakra-ui/react';
import { Link, Text, TextProps } from '@melio/penny';
import { FormattedMessage, useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

type TermsAndPolicyDisclaimerWidgetProps = Partial<TextProps> & { alignCenter?: boolean };

export const TermsAndPolicyDisclaimerWidget = forwardRef<TermsAndPolicyDisclaimerWidgetProps, 'div'>(
  ({ alignCenter, ...textProps }, ref) => {
    const { termsOfServiceUrl, privacyPolicyUrl } = useConfig().settings;
    const { formatMessage } = useMelioIntl();

    const termsAndPolicyDisclaimer = (
      <Text
        textStyle="body4"
        color="global.neutral.900"
        data-component="TermsAndPolicyDisclaimer"
        {...textProps}
        ref={ref}
      >
        <FormattedMessage
          id="widgets.termsAndPolicyDisclaimer.text"
          values={{
            termsOfService: (
              <Link
                href={termsOfServiceUrl}
                label={formatMessage('widgets.termsAndPolicyDisclaimer.termsOfServiceText')}
                newTab
              />
            ),
            privacyPolicy: (
              <Link
                href={privacyPolicyUrl}
                label={formatMessage('widgets.termsAndPolicyDisclaimer.privacyPolicyText')}
                newTab
              />
            ),
          }}
        />
      </Text>
    );

    return alignCenter ? <Box textAlign="center">{termsAndPolicyDisclaimer}</Box> : termsAndPolicyDisclaimer;
  }
);

TermsAndPolicyDisclaimerWidget.displayName = 'TermsAndPolicyDisclaimer';
