import { Theme } from '@melio/platform-provider';

export const citizensTheme: Theme = {
  logos: {
    light: 'https://platform-static.meliopayments.com/assets/citizens/FiservLogo.svg',
    dark: 'https://platform-static.meliopayments.com/assets/citizens/FiservLogoDark.svg',
  },
  fonts: {
    primary: 'Roboto,Arial,sans-serif',
  },
  colors: {
    global: {
      brand: {
        100: '#8AC7B1',
        200: '#8AC7B1',
        700: '#008555',
        800: '#00492F',
        900: '#00492F',
      },
      warning: {
        100: '#FDEFCE',
        700: '#F5B30B',
        900: '#231F20',
      },
      success: {
        100: '#E6F3EE',
        700: '#008555',
        900: '#231F20',
      },
      informative: {
        100: '#E6EDF6',
        700: '#3671B5',
        900: '#231F20',
      },
      critical: {
        100: '#F8EAEA',
        700: '#BC2A2A',
        900: '#231F20',
      },
    },
    semantic: {
      link: {
        primary: {
          rest: '{global.brand.700}',
          hover: '{global.brand.800}',
          pressed: '{global.brand.900}',
        },
      },
    },
    component: {
      pill: {
        secondaryCritical: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondarySuccess: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
        secondaryInformative: {
          rest: {
            label: '{global.neutral.1000}',
          },
        },
      },
    },
  },
  icons: {
    bank: 'https://platform-static.meliopayments.com/assets/citizens/Bank.svg',
  },
};
