import { Box } from '@chakra-ui/react';
import { Group, Icon, Text, TextProps, Tooltip } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React, { RefObject, useRef } from 'react';

export type LongTextWithTooltipWidgetProps = {
  tooltipContent: string;
} & Pick<TextProps, 'textStyle' | 'color'>;

export const LongTextWithTooltipWidget = forwardRef<LongTextWithTooltipWidgetProps, 'div'>(
  ({ tooltipContent, textStyle, color, children, ...props }, ref) => {
    const textRef = useRef<HTMLSpanElement>() as RefObject<HTMLSpanElement>;
    const { scrollWidth, clientWidth } = textRef.current ?? {};

    const shouldDisplayIconWithTooltip = scrollWidth && clientWidth && scrollWidth > clientWidth;

    return (
      <Group spacing="xxxs" alignItems="center" {...props} data-component="LongTextWithTooltipWidget" ref={ref}>
        <Text
          textStyle={textStyle}
          color={color}
          shouldSupportEllipsis
          ref={textRef}
          data-testid="long-text-with-tooltip-widget-text"
        >
          {children}
        </Text>
        {shouldDisplayIconWithTooltip && (
          <Tooltip content={tooltipContent} data-testid="long-text-with-tooltip-widget-tooltip">
            <Box as={Icon} type="info" size="small" tabIndex={0} />
          </Tooltip>
        )}
      </Group>
    );
  }
);

LongTextWithTooltipWidget.displayName = 'LongTextWithTooltipWidget';
