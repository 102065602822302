import { VendorDetailsFormType } from '@melio/platform-provider';

import { SettingsCardIdEnum } from '@/cl/components/SettingsCard/SettingsCard.component';
import { extendDefaultConfig } from '@/configByPartner/partnerConfig.utils';
import { SettingsGroupEnum } from '@/partnersConfig.types';

export const paypalConfig = extendDefaultConfig({
  default: {
    translationsSrc: 'https://platform-static.meliopayments.com/assets/paypal/messages.json',
    settings: {
      isEmbeddedExperience: true,
      hideHeaderNavigation: true,
      newBillExperience: {
        isRecurringEnabled: false,
      },
      settings: {
        options: [
          {
            type: SettingsGroupEnum.COMPANY,
            items: [
              SettingsCardIdEnum.ProfileSettings,
              SettingsCardIdEnum.CompanySettings,
              SettingsCardIdEnum.CollaboratorsSetting,
              SettingsCardIdEnum.NotificationPreferences,
              SettingsCardIdEnum.AccountingSoftwareSync,
            ],
          },
          {
            type: SettingsGroupEnum.PAYMENTS,
            items: [
              SettingsCardIdEnum.PaymentMethodsSetting,
              SettingsCardIdEnum.SubscriptionPlansSettings,
              SettingsCardIdEnum.BillingSetting,
            ],
          },
          {
            type: SettingsGroupEnum.SUPPORT,
            items: [SettingsCardIdEnum.SupportSetting],
          },
        ],
      },
      vendor: {
        forms: {
          shouldRequireEmail: true,
          shouldCollectCountry: true,
        },
        vendorSelect: {
          createVendor: {
            showVendorDetailsForm: true,
            vendorDetailsFormType: VendorDetailsFormType.Drawer,
          },
        },
      },
    },
  },
  prod: {},
  dev: {},
});
