import { chain } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { BaseTranslations, Translations } from './types';

export type LocalizationProviderProps<T extends BaseTranslations> = {
  messages: T;
  translations?: Translations<T>;
  locale?: string;
  shouldShowContentKeys?: boolean;
  isDefaultTZ?: boolean;
};

export function LocalizationProvider<T extends BaseTranslations>({
  children,
  locale = 'en',
  translations = {},
  shouldShowContentKeys = false,
  messages,
  isDefaultTZ,
}: PropsWithChildren<LocalizationProviderProps<T>>) {
  const combinedMessages = useMemo(
    () =>
      chain(messages)
        .assign(translations)
        .mapValues((value) => value ?? '')
        .value(),
    [messages, translations]
  );
  const targetMessages = shouldShowContentKeys
    ? Object.fromEntries(Object.entries(combinedMessages).map(([key]) => [key, key]))
    : combinedMessages;

  return (
    <IntlProvider
      locale={locale}
      messages={targetMessages}
      timeZone={isDefaultTZ ? undefined : 'UTC'}
      fallbackOnEmptyString={false}
    >
      {children}
    </IntlProvider>
  );
}

LocalizationProvider.displayName = 'LocalizationProvider';
