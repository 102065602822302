import { Theme } from '@melio/platform-provider';

export const melioTheme: Theme = {
  logos: {
    dark: 'https://platform-static.meliopayments.com/assets/melio/MelioLogoDark.svg',
    light: 'https://platform-static.meliopayments.com/assets/melio/MelioLogo.svg',
  },
  illustrations: {
    gift: {
      src: 'https://platform-static.meliopayments.com/assets/melio/gift.lottie.json',
      type: 'animation',
    },
    'add-card': {
      src: 'https://platform-static.meliopayments.com/assets/melio/add-card.lottie.json',
      type: 'animation',
    },
    bank: {
      src: 'https://platform-static.meliopayments.com/assets/melio/bank.lottie.json',
      type: 'animation',
    },
    'bank-success': {
      src: 'https://platform-static.meliopayments.com/assets/melio/bank-success.lottie.json',
      type: 'animation',
    },
    'bank-missing': {
      src: 'https://platform-static.meliopayments.com/assets/melio/bank-missing.lottie.json',
      type: 'animation',
    },
    blocked: {
      src: 'https://platform-static.meliopayments.com/assets/melio/blocked.lottie.json',
      type: 'animation',
    },
    celebration: {
      src: 'https://platform-static.meliopayments.com/assets/melio/celebration.lottie.json',
      type: 'animation',
    },
    'customer-add': {
      src: 'https://platform-static.meliopayments.com/assets/melio/customer-add.lottie.json',
      type: 'animation',
    },
    declined: {
      src: 'https://platform-static.meliopayments.com/assets/melio/declined.lottie.json',
      type: 'animation',
    },
    expired: {
      src: 'https://platform-static.meliopayments.com/assets/melio/expired.lottie.json',
      type: 'animation',
    },
    'money-success': {
      src: 'https://platform-static.meliopayments.com/assets/melio/money-success.lottie.json',
      type: 'animation',
    },
    'network-download': {
      src: 'https://platform-static.meliopayments.com/assets/melio/network-download.lottie.json',
      type: 'animation',
    },
    'network-error': {
      src: 'https://platform-static.meliopayments.com/assets/melio/network-error.lottie.json',
      type: 'animation',
    },
    'new-email': {
      src: 'https://platform-static.meliopayments.com/assets/melio/new-email.lottie.json',
      type: 'animation',
    },
    'no-items': {
      src: 'https://platform-static.meliopayments.com/assets/melio/no-items.lottie.json',
      type: 'animation',
    },
    'payment-request-link': {
      src: 'https://platform-static.meliopayments.com/assets/melio/payment-request-link.lottie.json',
      type: 'animation',
    },
    'pay-zero': {
      src: 'https://platform-static.meliopayments.com/assets/melio/pay-zero.lottie.json',
      type: 'animation',
    },
    'page-not-found': {
      src: 'https://platform-static.meliopayments.com/assets/melio/page-not-found.lottie.json',
      type: 'animation',
    },
    pending: {
      src: 'https://platform-static.meliopayments.com/assets/melio/pending.lottie.json',
      type: 'animation',
    },
    notification: {
      src: 'https://platform-static.meliopayments.com/assets/melio/notification.lottie.json',
      type: 'animation',
    },
    processing: {
      src: 'https://platform-static.meliopayments.com/assets/melio/processing.lottie.json',
      type: 'animation',
    },
    sent: {
      src: 'https://platform-static.meliopayments.com/assets/melio/sent.lottie.json',
      type: 'animation',
    },
    'small-business': {
      src: 'https://platform-static.meliopayments.com/assets/melio/small-business.lottie.json',
      type: 'animation',
    },
    success: {
      src: 'https://platform-static.meliopayments.com/assets/melio/success.lottie.json',
      type: 'animation',
    },
    'sync-accounts': {
      src: 'https://platform-static.meliopayments.com/assets/melio/sync-accounts.lottie.json',
      type: 'animation',
    },
    'sync-user': {
      src: 'https://platform-static.meliopayments.com/assets/melio/sync-user.lottie.json',
      type: 'animation',
    },
    'vendor-add': {
      src: 'https://platform-static.meliopayments.com/assets/melio/vendor-add.lottie.json',
      type: 'animation',
    },
    'tax-form': {
      src: 'https://platform-static.meliopayments.com/assets/melio/tax-form.lottie.json',
      type: 'animation',
    },
    together: {
      src: 'https://platform-static.meliopayments.com/assets/melio/together.lottie.json',
      type: 'animation',
    },
    fast: {
      src: 'https://platform-static.meliopayments.com/assets/melio/fast.lottie.json',
      type: 'animation',
    },
  },
};
