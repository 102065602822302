//export { FiservProviderAllowedPartners, PartnerName } from '@melio/platform-api-axios-client';

//It was imported from the open-api, and here temporarily to avoid changes in a lot of places
export const PartnerName = {
  WellsFargo: 'wells-fargo',
  CapitalOne: 'capital-one',
  Clover: 'clover',
  Melio: 'melio',
  Shopify: 'shopify',
  Sbb: 'sbb',
  Fiserv: 'fiserv',
  BuiltTechnologies: 'built-technologies',
  FiservUsBank: 'fiserv_us-bank',
  FiservXdDemo: 'fiserv_xd-demo',
  FiservFirstFinancial: 'fiserv_first-financial',
  FiservCitizens: 'fiserv_citizens',
  DeploymentsDemo: 'deployments-demo',
  Paypal: 'paypal',
};

// export const FiservProviderAllowedPartners = {
//   FiservUsBank: 'fiserv_us-bank',
//   FiservXdDemo: 'fiserv_xd-demo',
//   FiservFirstFinancial: 'fiserv_first-financial',
// } as const;

export type PartnerName = (typeof PartnerName)[keyof typeof PartnerName];
