import { Route, Routes } from 'react-router-dom';
import { PartnerName } from '@melio/platform-api';

import { VexConfigurationProvider } from '@/cl/components/PartnerApp/app-providers/VexConfigurationProvider.component';
import {
  AuthActionRoute as VexAuthActionRoute,
  CollectW9Route,
  EmailVerificationRoute,
  NotFoundRoute,
  PaymentUpgradeRoute,
  SuvcAcceptanceRoute,
  UnilateralRoute,
  VendorOnboardingRoute,
} from '@/router/routes/vex';
import { VendorPaymentTrackingRoute } from '@/router/routes/vex/VendorPaymentTracking.route';

export const VexAppRoutes = ({ partnerName }: { partnerName: PartnerName }) => (
  <Routes>
    <Route element={<VexConfigurationProvider partnerName={partnerName} />}>
      <Route path="auth/action" element={<VexAuthActionRoute />} />
      <Route path="unilateral/:paymentId/*" element={<UnilateralRoute />} />
      <Route path="payment-upgrade/:paymentId/*" element={<PaymentUpgradeRoute />} />
      <Route path="email-verification/*" element={<EmailVerificationRoute />} />
      <Route path="suvc-acceptance/:paymentId/*" element={<SuvcAcceptanceRoute />} />
      <Route path="vendor-onboarding/:onboardingSessionUuid/*" element={<VendorOnboardingRoute />} />
      <Route path="track-payment/:paymentId/*" element={<VendorPaymentTrackingRoute />} />
      <Route path="collect-w9/:vendorId/*" element={<CollectW9Route />} />
      <Route path="*" element={<NotFoundRoute />} />
    </Route>
  </Routes>
);
