import { featureFlags } from '@melio/shared-web';
import { UseFeatureOptions } from '@melio/shared-web/dist/feature-flags';

import { FeatureFlags } from './devFeatureList';

export { FeatureFlags };

export const useDevFeature = <T>(flagKey: FeatureFlags, defaultValue: T, options?: UseFeatureOptions) =>
  featureFlags.useFeature(flagKey, defaultValue, options);

export const useAnonymousFeature = <T>(flagKey: FeatureFlags, defaultValue: T, options?: UseFeatureOptions) =>
  featureFlags.useAnonymousFeature(flagKey, defaultValue, options);
export const useIsLoadingFeatureFlags = (isAnonymous?: boolean) => featureFlags.useIsLoadingFeatureFlags(isAnonymous);
