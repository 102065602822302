import { useMelioQueryClient, useModelApi, UseModelApiProps } from '@melio/api-client';
import { GetMeOrgData, VexOrganizationsApiClient } from '@melio/platform-api-axios-client';

// We must pass an argument to queryFn, so we use a dummy string
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const queryFn = (_?: string) => VexOrganizationsApiClient.getMyOrganization();

export const useVexMyOrganization = (props?: UseModelApiProps<typeof queryFn>) => {
  const { removeQueries } = useMelioQueryClient();
  const { data, error, ...rest } = useModelApi({
    ...props,
    id: 'me',
    queryKey: ['VexOrganizationsApi', 'getMyOrganization'],
    queryFn,
    retry: false,
  });

  const removeQuery = () => removeQueries('VexOrganizationsApi', 'model', 'me');

  return { data, error, removeQuery, ...rest };
};

export type { GetMeOrgData };
