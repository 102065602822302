/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { getAccessTokenData } from '../utils/getAccessTokenData.utils';

export const useFeedbackProviderProps = () => {
  const [isFeedbackWidgetOn] = useDevFeature(FeatureFlags.FeedbackWidget, false);
  const [feedbackProps, setFeedbackProps] = useState<{
    userId?: string;
    userEmail?: string;
    userCreatedAt?: string;
    organizationId?: string;
    isFeedbackWidgetOn?: boolean;
  }>({});
  const accessTokenData = getAccessTokenData();

  const getFeedbackProps = () => {
    if (accessTokenData) {
      const { organization, user } = accessTokenData;

      setFeedbackProps({
        userId: user?.id,
        userEmail: user?.email,
        userCreatedAt: user?.createdAt ? format(new Date(user?.createdAt), 'MM/dd/yyyy') : undefined,
        organizationId: organization?.id,
        isFeedbackWidgetOn,
      });
    }
  };

  useEffect(() => {
    getFeedbackProps();
  }, [isFeedbackWidgetOn]);

  return [feedbackProps];
};
